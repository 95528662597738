<template><v-app>
  <div>
    <div class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(ld16, index) in ld16s" :key="ld16.hardwareId">
      <h2 class="mb-4">LD16 {{ld16.hardwareId}}</h2>

      <Ld16Config :ld16s=createLd16Array(ld16) :show-hardware-id="false" :show-delete-button="false" />

      <Testen :tests=ld16tests :data=data hardware-key="hardwareId" :current-hardware-id=ld16.hardwareId :is-blue-background="index % 2 === 1"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The Ld16 page contains the LD16 tests of all the LD16s configured in the currently selected test session.
 *  component: Ld16Config - summary table of LD16s in the current iwks configuration
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Ld16Config from '@/components/Ld16Config.vue'
import Testen from '@/components/Testen.vue'
import util from '@/components/util'

export default {
  name: 'ld16',
  apollo: {
    /**
     *  Subscription on all LD16 tests for data used to display in the Testen component
     *  variable: currently selected test session
     *  If the first test (connection test) does not contain data then there are no LD16 tests. Reroute to falcon radar testen page.
     */
      $subscribe: {
        subs: {
          query: gql`subscription ld16Subscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { ld16s { hardwareId, host, port, icpconId, icpconRelaisNummer } },
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
          }
          }`,
          variables () {
            // Use vue reactive properties here
            return {
                id: this.testSessionId
            }
          },
          // Result hook
          // Don't forget to destructure `data`
          result ({ data }) {
            if (data.testSessionChanged.tests.filter(test => test.testType == "LD16_CONNECT").length === 0) {
              this.$router.push('/falconradartesten');
            } else {
              this.data = data;
              this.ld16s = data.testSessionChanged.iwksConfiguration.ld16s;
            }
          }
        },
      },
      
    },

    data: () => ({
      /**
       *  ld16s:  used to store apollo subscription result of the LD16 configuration in iwksConfiguration
       *  ld16tests: metadata of the LD16 tests
       *  data: used to store apollo subscription result in its entirety 
       */
      ld16s: [],

      ld16tests: {
        LD16_CONNECT: util.getTestInformationMap("LD16_CONNECT"),
        LD16_RECONNECT: util.getTestInformationMap("LD16_RECONNECT")
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    methods: {
      /**
       *  vue-prop-data: the component Ld16Config expects an array. Convert the object to an array of 1 object.
       */
      createLd16Array(ld16) {
        let ld16s = [];
        ld16s.push(ld16);
        return ld16s;
      }
    },

    components: {
      Ld16Config,
      Testen,
    }
}
</script>