<template><v-app>
  <div>

    <div class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(profibus, index) in profibussen" :key="profibus.profibusId">       
      <h2 class="mb-4">Profibus {{profibus.profibusId}}</h2>

      <ProfibusConfig :profibuses=createProfibusArray(profibus) :show-hardware-id="false" :show-delete-button="false" />

      <Testen :tests=profibustests :data=data hardware-key="profibusId" :current-hardware-id=profibus.profibusId :is-blue-background="index % 2 === 1"/>
           
    </div>
  </div>
</v-app></template>

<script>
/**
 *  The Profibus page contains the profibus tests of all the profibuses configured in the currently selected test session.
 *  component: ProfibusConfig - summary table of profibuses in the current iwks configuration
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import ProfibusConfig from '@/components/ProfibusConfig.vue'
import Testen from '@/components/Testen.vue'
import util from '@/components/util'

export default {
  name: 'Profibus',
  apollo: {
    /**
     *  Subscription on all profibus tests for data used to display in the Testen component
     *  variable: currently selected test session
     *  If the first test (connection test) does not contain data then there are no profibus tests. Reroute to UPS testen page.
     */
      $subscribe: {
        subs: {
          query: gql`subscription profibusSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { profibussen { profibusId, host, icpconId, icpconRelaisNummer, mussen { pbSlaveAddress, rotatiePaneel } } } ,
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
            }
          }`,
          variables () {
            // Use vue reactive properties here
            return {
                id: this.testSessionId
            }
          },
          // Result hook
          // Don't forget to destructure `data`
          result ({ data }) {
            if (data.testSessionChanged.tests.filter(test => test.testType == "PROFIBUS_GATEWAY_CONNECT").length === 0) {
              this.$router.push('/upstesten');
            } else {
              this.data = data;
              this.profibussen = data.testSessionChanged.iwksConfiguration.profibussen;
            }
          },
        },
      },
      
    },

    data: () => ({
      /**
       *  profibussen:  used to store apollo subscription result of the profibus configuration in iwksConfiguration
       *  profibustests: metadata of the profibus tests
       *  data: used to store apollo subscription result in its entirety 
       */
      profibussen: [],

      profibustests: {
        PROFIBUS_GATEWAY_CONNECT: util.getTestInformationMap("PROFIBUS_GATEWAY_CONNECT"),
        PROFIBUS_GATEWAY_RECONNECT: util.getTestInformationMap("PROFIBUS_GATEWAY_RECONNECT")
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
          return this.$store.getters.getCurrentTestSessionId;
      }
    },

    methods: {
      /**
       *  vue-prop-data: the component ProfibusConfig expects an array. Convert the object to an array of 1 object. 
       */
      createProfibusArray(profibus) {
        var profibussen = [];
        profibussen.push(profibus);
        return profibussen;
      }
    },

    components: {
      ProfibusConfig,
      Testen,
    }
}
</script>