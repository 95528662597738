<template><v-app>
  <div>
    <div class="pa-6">        
      <h2 class="mb-4">AMT</h2>

      <Testen :tests=amttests :data=data current-hardware-id="AMT"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The AMT page contains the AMT tests of the currently selected test session.
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Testen from '@/components/Testen.vue'
import util from '@/components/util'

export default {
  name: 'AMT',
  apollo: {
    /**
     *  Subscription on all AMT tests for data used to display in the Testen component
     *  variable: currently selected test session
     */
    $subscribe: {
      subs: {
        query: gql`subscription myAmtSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
            }
          }`,
        variables () {
          // Use vue reactive properties here
          return {                
              id: this.testSessionId
          }
        },
        // Result hook
        // Don't forget to destructure `data`
        result ({ data }) {
          if (data.testSessionChanged.tests.filter(test => test.testType == "AMT_CONNECT").length === 0) {
            this.$router.push('/lanswitchtesten'); 
          } else {
            this.data = data;
          }
        }
      },
    },
  },

    data: () => ({
      /**
       *  amttests: metadata of the AMT tests
       *  data: used to store apollo subscription result 
       */
      amttests: {
        AMT_CONNECT: util.getTestInformationMap("AMT_CONNECT"),
        AMT_TOEGANG: util.getTestInformationMap("AMT_TOEGANG"),
        AMT_BEHEER_NETWERK: util.getTestInformationMap("AMT_BEHEER_NETWERK")
      },
      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    components: {
      Testen,
    }
}
</script>