<template>
<v-dialog v-model="show" max-width="500px" :retain-focus="false" v-if="instructions[step]">
  <v-card >
    <v-card-title >
        {{instructions[step].title}}
        <v-spacer></v-spacer>
        <span v-if="step!=0">{{step}}/{{lastStep}}</span>
    </v-card-title>
    <v-card-text >
        <div v-html="instructions[step].text"></div>
    </v-card-text>
    <v-img 
        v-if="instructions[step] && instructions[step].image" 
        :src="require(`../assets/${instructions[step].image}`)"
        contain
        height="400px"
        ></v-img>
    <v-card-actions>
      <v-btn color="primary" @click="step -= 1" :disabled="step==0" >Vorige</v-btn>
      <v-btn color="primary" @click="step += 1" :disabled="isLastStep">Volgende</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" @click.stop="closeDialog()">Sluiten<v-icon small color="white">mdi-cancel</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
/**
  *  The TestInstructions component is a popup screen with step by step instructions how to setup and execute the test.
  */
export default {
  /**
   *  value: boolean - used to determine whether the popup shold be visible or not
   *  instructions: Object - containing the metadata or the test instruction. 
   */
  props: {
     value: Boolean,
     instructions: {
         type: Array,
         required: true,
         default: () => {
             return [];
         }
     }
  },
  /**
   *  step: used to keep track of which step of the instructions is shown in the popup
   */
  data: () => ({
      step: 0
  }),
  computed: {
    /**
     *  Methods necessary because the popup is created in a vue for-loop 
     *  Reset step value to 0 when you close the popup
     */
    show: {
      get () {
        return this.value
      },
      set (value) {
         if (value == false) {
            this.step=0;
         }
         this.$emit('input', value)
      }
    },
    /**
     *  Returns the integer number of the last step in the instructions
     */
    lastStep() {
        if (!this.instructions) return;
        return Object.keys(this.instructions).length -1;
    },

   /**
     *  Returns whether we are currently at the last step in the instructions
     */
    isLastStep() {
        if (!this.instructions) return;
        return this.step === Object.keys(this.instructions).length -1;
    }
  },
  watch: {
      /**
       *  Keep track of step. You can never go below step 0, and never above the last step.
       *  Corresponding next and previous buttons will be disabled.
       */
      step: function() {
          if (this.step < 0) this.step = 0;
          if (this.step > this.lastStep) this.step = this.lastStep;
      }
  },
  methods: {
      /**
       *  Button click closeDialog
       *  Resets step value to 0 and sets boolean to show popup to false
       */
      closeDialog() {
          this.step=0;
          this.show=false;
      }
  }
}
</script>